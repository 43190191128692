define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "truth-helpers", "discourse/components/user-link", "discourse/helpers/avatar", "discourse/helpers/helper-fn", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _truthHelpers, _userLink, _avatar, _helperFn, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ComposerPresenceDisplay extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composerPresenceManager", [_service.service]))();
    #composerPresenceManager = (() => (dt7948.i(this, "composerPresenceManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "replyChannel", [_tracking.tracked]))();
    #replyChannel = (() => (dt7948.i(this, "replyChannel"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "whisperChannel", [_tracking.tracked]))();
    #whisperChannel = (() => (dt7948.i(this, "whisperChannel"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "editChannel", [_tracking.tracked]))();
    #editChannel = (() => (dt7948.i(this, "editChannel"), void 0))();
    setupReplyChannel = (() => (0, _helperFn.default)((_, on) => {
      const topic = this.args.model.topic;
      if (!topic || !this.isReply) {
        return;
      }
      const replyChannel = this.presence.getChannel(`/discourse-presence/reply/${topic.id}`);
      replyChannel.subscribe();
      this.replyChannel = replyChannel;
      on.cleanup(() => replyChannel.unsubscribe());
    }))();
    setupWhisperChannel = (() => (0, _helperFn.default)((_, on) => {
      if (!this.args.model.topic || !this.isReply || !this.currentUser.staff || !this.currentUser.whisperer) {
        return;
      }
      const whisperChannel = this.presence.getChannel(`/discourse-presence/whisper/${this.args.model.topic.id}`);
      whisperChannel.subscribe();
      this.whisperChannel = whisperChannel;
      on.cleanup(() => whisperChannel.unsubscribe());
    }))();
    setupEditChannel = (() => (0, _helperFn.default)((_, on) => {
      if (!this.args.model.post || !this.isEdit) {
        return;
      }
      const editChannel = this.presence.getChannel(`/discourse-presence/edit/${this.args.model.post.id}`);
      editChannel.subscribe();
      this.editChannel = editChannel;
      on.cleanup(() => editChannel.unsubscribe());
    }))();
    notifyState = (() => (0, _helperFn.default)((_, on) => {
      const {
        topic,
        post,
        reply
      } = this.args.model;
      const raw = this.isEdit ? post?.raw || "" : "";
      const entity = this.isEdit ? post : topic;
      if (reply !== raw) {
        this.composerPresenceManager.notifyState(this.state, entity?.id);
      }
      on.cleanup(() => this.composerPresenceManager.leave());
    }))();
    get isReply() {
      return this.state === "reply" || this.state === "whisper";
    }
    get isEdit() {
      return this.state === "edit";
    }
    get state() {
      if (this.args.model.editingPost) {
        return "edit";
      } else if (this.args.model.whisper) {
        return "whisper";
      } else if (this.args.model.replyingToTopic) {
        return "reply";
      }
    }
    get users() {
      let users;
      if (this.isEdit) {
        users = this.editChannel?.users || [];
      } else {
        const replyUsers = this.replyChannel?.users || [];
        const whisperUsers = this.whisperChannel?.users || [];
        users = [...replyUsers, ...whisperUsers];
      }
      return users.filter(u => u.id !== this.currentUser.id).slice(0, this.siteSettings.presence_max_users_shown);
    }
    static #_8 = (() => dt7948.n(this.prototype, "users", [_tracking.cached]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{this.setupReplyChannel}}
        {{this.setupWhisperChannel}}
        {{this.setupEditChannel}}
        {{this.notifyState}}
    
        {{#if (gt this.users.length 0)}}
          <div class="presence-users">
            <div class="presence-avatars">
              {{#each this.users as |user|}}
                <UserLink @user={{user}}>
                  {{avatar user imageSize="small"}}
                </UserLink>
              {{/each}}
            </div>
    
            <span class="presence-text">
              <span class="description">
                {{~#if this.isReply~}}
                  {{i18n "presence.replying" count=this.users.length}}
                {{~else~}}
                  {{i18n "presence.editing" count=this.users.length}}
                {{~/if~}}
              </span>
              <span class="wave">
                <span class="dot">.</span>
                <span class="dot">.</span>
                <span class="dot">.</span>
              </span>
            </span>
          </div>
        {{/if}}
      
    */
    {
      "id": "zqhHwS/X",
      "block": "[[[1,\"\\n    \"],[1,[30,0,[\"setupReplyChannel\"]]],[1,\"\\n    \"],[1,[30,0,[\"setupWhisperChannel\"]]],[1,\"\\n    \"],[1,[30,0,[\"setupEditChannel\"]]],[1,\"\\n    \"],[1,[30,0,[\"notifyState\"]]],[1,\"\\n\\n\"],[41,[28,[32,0],[[30,0,[\"users\",\"length\"]],0],null],[[[1,\"      \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"            \"],[8,[32,1],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,2],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"description\"],[12],[41,[30,0,[\"isReply\"]],[[[1,[28,[32,3],[\"presence.replying\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]]],[]],[[[1,[28,[32,3],[\"presence.editing\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]]],[]]],[13],[1,\"\\n          \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"user\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-presence/discourse/components/composer-presence-display.js",
      "scope": () => [_truthHelpers.gt, _userLink.default, _avatar.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ComposerPresenceDisplay;
});